import api from './api';

export function getProduk(keyword, limit, page){
    return api.get(`/produk/api/produk?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

// kategori
export function getKategori(keyword, limit, page){
    return api.get(`/produk/api/kategori-produk?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postKategori(form){
    return api.post(`/produk/api/kategori-produk`, form).then(res => res.data);
}

export function putKategori(form, id){
    return api.put(`/produk/api/kategori-produk/${id}`, form).then(res => res.data);
}

// satuan
export function getSatuan(keyword, limit, page){
    return api.get(`/produk/api/satuan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putSatuan(form, id){
    return api.put(`/produk/api/satuan/${id}`, form).then(res => res.data);
}

export function deleteSatuan(id){
    return api.delete(`/produk/api/satuan/${id}`).then(res => res.data);
}

export function postSatuan(form){
    return api.post(`/produk/api/satuan`, form).then(res => res.data);
}

// lokasi
export function getLokasi(keyword, limit, page){
    return api.get(`/produk/api/lokasi?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putLokasi(form, id){
    return api.put(`/produk/api/lokasi/${id}`, form).then(res => res.data);
}

export function deleteLokasi(id){
    return api.delete(`/produk/api/lokasi/${id}`).then(res => res.data);
}

export function postLokasi(form){
    return api.post(`/produk/api/lokasi`, form).then(res => res.data);
}


export function deleteKategori(id){
    return api.delete(`/produk/api/kategori-produk/${id}`).then(res => res.data);
}


export function getProdukDetail(keyword, limit, page){
    return api.get(`/produk/api/produk/view-detail?${keyword}`, {params:{limit, page}}).then(res => res.data);
}
export function postProduk(form){
    return api.post(`/produk/api/produk`, form).then(res => res.data);
}

export function deleteData(id){
    return api.delete(`/produk/api/produk/${id}`).then(res => res.data);
}

export function deleteProdukDetail(id){
    return api.delete(`/produk/api/produk/view-detail/${id}`).then(res => res.data);
}


export function editData(form, id){
    return api.put(`/produk/api/produk/${id}`, form).then(res => res.data);
}

export function updateData(form, id){ // edit data yang baru pakai coreui
    return api.put(`/produk/api/update-produk/${id}`, form).then(res => res.data);
}

export function getStock(produk_id){
    return api.get(`/produk/api/get-produk-stock?produk_id=${produk_id}`)
}

export function getProdukLog(keyword, limit, page){
    return api.get(`/produk/api/log?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


// upload foto
export function uploadFotoProduk(formData){
    return api.post(`/produk/api/produk/foto`, formData).then(res => res.data);
}

export function changePrimaryFotoProduk(formData, id){
    return api.put(`/produk/api/produk/foto/${id}`, formData).then(res => res.data);
}

export function deleteFotoProduk(id){
    return api.delete(`/produk/api/produk/foto/${id}`).then(res => res.data);
}

// produk_satuan
export function getProdukSatuan(params){
    return api.get(`/produk/api/produk-satuan?${params}`).then(res => res.data);
}

// produk_satuan
export function postPenyesuaianProduk(formData){
    return api.post(`/produk/api/penyesuaian-stock`, formData).then(res => res.data);
}

export function getPenyesuaianProduk(keyword, limit, page){
    return api.get(`/produk/api/penyesuaian-stock${keyword}`, {params:{limit, page}}).then(res => res.data);
}