import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import PerusahaanComp from '../../containers/Components/PerusahaanComp';
import { Row, Col, Table } from 'reactstrap';
import '../../style/mirrorStyle.css';
import StickyTable from "react-sticky-table-thead";

const PenjualanMirrorPage = (props) => {    
    const [listData, setListData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        function checkUserData() {
          const item = localStorage.getItem('listPenjualan')
      
          if (item) {
            // myFunc.setListPenjualanValue();
            let tmp = []
            let array = JSON.parse(item);
            let totalTmp = 0;
            array.map(post => {
                let obj = {...post}
                let filter = post.satuan_obj.filter(x => parseInt(x.value) === parseInt(post.satuan_id))
                obj['satuan_name'] = filter.length > 0 ? filter[0].label : '';      
                obj['sub_total'] = parseInt(post.harga) * parseInt(post.qty);  
                totalTmp += parseInt(post.qty) * parseInt(post.harga);
                tmp.push(obj);                        
                return true;
            });
            setTotal(totalTmp);
            setListData(tmp)
          }
        }
      
        window.addEventListener('storage', checkUserData)
      
        return () => {
          window.removeEventListener('storage', checkUserData)
        }
    }, [])

    const numberToString = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
    
    return(
        <Fragment>
            <div className='mirror-container'>
                <div className="mirror-content">
                    <Row>
                        <Col lg={4} md={4}>                        
                            <PerusahaanComp/>
                            <div className='total-belanja'>
                                <div>Total Belanja</div>
                                <div className='text-right total-nominal'>Rp. {numberToString(total)}</div>
                            </div>
                        </Col>
                        <Col lg={8} md={8}>
                            <StickyTable height={window.screen.height - 300}>
                            <Table className='table-striped table-center'>
                                <thead>
                                    <tr>
                                        <th colSpan="2">Daftar Belanja</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>
                                                    <div>{post.qty} {post.satuan_name} {post.nama} x Rp. {numberToString(post.harga)}</div>
                                                </td>                                                
                                                <td className="text-right">{numberToString(post.sub_total)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            </StickyTable>
                        </Col>
                    </Row>
                </div>
                
                </div>
        </Fragment>
    )
} 
export default PenjualanMirrorPage