import api from './api';

export function getTipePembayaran(){
    return api.get(`/penjualan/api/get-tipe-pembayaran`).then(res => res.data);
}

export function postPenjualan(formData){
    return api.post(`/penjualan/api/pos`, formData).then(res => res.data);
}

export function putPenjualan(formData, id){
    return api.put(`/penjualan/api/pos/${id}`, formData).then(res => res.data);
}

export function getPenjualan(keyword, limit, page){
    return api.get(`/penjualan/api/penjualan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getPenjualanDetail(keyword, limit, page){
    return api.get(`/penjualan/api/penjualan-detail?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function returPenjualan(id){
    return api.post(`/penjualan/api/retur/${id}`).then(res => res.data);
}