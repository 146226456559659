import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';


// import './style/customStyle.css';

import { unregister } from './serviceWorker';
import DepositeDetailPrint from './views/Laporan/Cetak/DepositeDetailPrint';
import HutangDetailPrint from './views/Laporan/Cetak/HutangDetailPrint';
import HutangPrint from './views/Laporan/Cetak/HutangPrint';
import PembelianDetailPrint from './views/Laporan/Cetak/PembelianDetailPrint';
import PembelianPrint from './views/Laporan/Cetak/PembelianPrint';
import PengeluaranPrint from './views/Laporan/Cetak/PengeluaranPrint';
import PenjualanPrint from './views/Laporan/Cetak/PenjualanPrint';
import PenjualanProfitPrint from './views/Laporan/Cetak/PenjualanProfitPrint';
import PiutangDetailPrint from './views/Laporan/Cetak/PiutangDetailPrint';
import PiutangPegawaiDetailPrint from './views/Laporan/Cetak/PiutangPegawaiDetailPrint';
import PiutangPrint from './views/Laporan/Cetak/PiutangPrint';
import ProdukPrint from './views/Laporan/Cetak/ProdukPrint';
import ProduksiDetailPrint from './views/Laporan/Cetak/ProduksiDetailPrint';
import ProfitAndLossPrint from './views/Laporan/Cetak/ProfitAndLossPrint';
import StrukPrint from './views/Laporan/Cetak/StrukPrint';
import PenjualanMirrorPage from './views/Penjualan/PenjualanMirrorPage';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'))
// const CetakMasterBill = React.lazy(() => import('./views/Room/Room/CetakMasterBill'));
unregister();

const App = () => {
	return (
		
		<HashRouter>
			<React.Suspense fallback={loading()}>
				<Switch>
					<Route exact path="/login" name="Login Page" render={props => <LoginPage {...props} />} />
					<Route exact path="/laporan/cetak-penjualan" name="Laporan Penjualan" render={props => <PenjualanPrint {...props} />} />
					<Route exact path="/penjualan/mirroring" name="Mirroring" render={props => <PenjualanMirrorPage {...props} />} />
					<Route exact path="/laporan/struk-penjualan" name="Struk Penjualan" render={props => <StrukPrint {...props} />} />
					<Route exact path="/laporan/cetak-pembelian" name="Laporan Pembelian" render={props => <PembelianPrint {...props} />} />
					<Route exact path="/laporan/cetak-pembelian-detail" name="Laporan Pembelian Detail" render={props => <PembelianDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-hutang" name="Laporan Hutang" render={props => <HutangPrint {...props} />} />
					<Route exact path="/laporan/cetak-hutang-detail" name="Laporan Hutang Detail" render={props => <HutangDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-piutang-penjualan" name="Laporan Piutang" render={props => <PiutangPrint {...props} />} />
					<Route exact path="/laporan/cetak-piutang-detail" name="Laporan Piutang Detail" render={props => <PiutangDetailPrint {...props} />} />
					<Route exact path="/laporan/cetak-piutang-pegawai-detail" name="Laporan Piutang Pegawai Detail" render={props => <PiutangPegawaiDetailPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-pengeluaran" name="Laporan Pengeluaran" render={props => <PengeluaranPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-profit-penjualan" name="Laporan Profit Penjualan" render={props => <PenjualanProfitPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-produksi-detail" name="Laporan Produksi Detail" render={props => <ProduksiDetailPrint {...props} />} />

					<Route exact path="/laporan/cetak-profit-and-loss" name="Laporan Produksi Detail" render={props => <ProfitAndLossPrint {...props} />} />
					
					<Route exact path="/laporan/cetak-deposite-detail" name="Laporan Deposite Detail" render={props => <DepositeDetailPrint {...props} />} />
					<Route exact path="/laporan/cetak-produk" name="Daftar Produk" render={props => <ProdukPrint {...props} />} />

					
					<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
				</Switch>
			</React.Suspense>
		</HashRouter>		
	);
}

export default App;
