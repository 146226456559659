import api from './api';

export function getPembelian(keyword, limit, page){
    return api.get(`/pembelian/api/pembelian?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putPembelian(form, id){
    return api.put(`/pembelian/api/pembelian/${id}`, form).then(res => res.data);
}

export function deletePembelian(id){
    return api.delete(`/pembelian/api/pembelian/${id}`).then(res => res.data);
}

export function postPembelian(form){
    return api.post(`/pembelian/api/pembelian`, form).then(res => res.data);
}

export function returPembelian(form, id){
    return api.post(`/pembelian/api/retur/${id}`, form).then(res => res.data);
}