import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row, Table,  } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as accountingService from '../../../services/accountingService';
import Moment from 'moment'

const HutangPrint = (props) => {    
    const [listData, setListData] = useState([]);
    const [tanggal, setTanggal] = useState('');
    const [totalHutang, setTotalHutang] = useState(0);
    useEffect(() => {
        (async() => {
            let date = new Date()
            setTanggal(Moment(date).format('DD-MM-YYYY hh:mm'))
            try{                                        
                let res = await accountingService.getHutang(`tipe=penjualan&show=by_kontak_and_account&have_saldo=true`, 0, 1);
                setListData(res.data);
                let total = 0;
                res.data.map(post => {
                    total += parseInt(post.saldo);
                    return true;
                });
                setTotalHutang(total);
            }catch(error){
                return;
            }
        })()    
    },[])


    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Hutang</div>
                        <div className="text-center" style={{fontStyle:'italic'}}>Tanggal Cetak {tanggal}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table className='table-small table-bordered table-center' style={{marginTop: 20}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama</th>
                                    <th>Saldo Hutang</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{post.nomor}</td>
                                            <td>{post.nama_kontak}</td>
                                            <td className='text-right'>{post.saldo.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            { parseInt(totalHutang) > 0 &&
                                <tfoot>
                                    <tr>
                                        <th className='text-right' colSpan={2}>Total Hutang</th>
                                        <th className='text-right'>{totalHutang.toLocaleString()}</th>
                                    </tr>
                                </tfoot>
                            }
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default HutangPrint