import api from './api';


export function getPerusahaan(){
    return api.get(`/master/api/perusahaan`).then(res => res.data);
}

export function updatePerusahaan(formData){
    return api.post(`/master/api/perusahaan`, formData).then(res => res.data);
}

export function getProvinsi(){
    return api.get(`/master/api/provinsi`).then(res => res.data);
}

export function getKabupaten(provinsi_id){
    return api.get(`/master/api/kabupaten?provinsi=${provinsi_id}`).then(res => res.data);
}

export function getKecamatan(kabupaten_id){
    return api.get(`/master/api/kecamatan?kabupaten_id=${kabupaten_id}`).then(res => res.data);
}

export function getDesa(kecamatan_id){
    return api.get(`/master/api/desa?kecamatan_id=${kecamatan_id}`).then(res => res.data);
}

// shift pegawai
export function getMasterShift(keyword, limit, page){
    return api.get(`/master/api/master-shift?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putShift(form, id){
    return api.put(`/master/api/master-shift/${id}`, form).then(res => res.data);
}

export function deleteShift(id){
    return api.delete(`/master/api/master-shift/${id}`).then(res => res.data);
}

export function postShift(form){
    return api.post(`/master/api/master-shift`, form).then(res => res.data);
}