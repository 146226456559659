import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Table, FormGroup } from 'reactstrap';

const HutangDetailCetakComp = (props) => {
    return(
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Hutang Detail</div>
                    <div className="text-center">Periode {props.startDate} s.d {props.endDate}</div>
                </Col>
            </Row>
            <div style={{marginTop: 20}}></div>
            <Row>
                <Col lg={6}>
                    <FormGroup row className='form-group-pos'>
                        <Col lg={4} md={4}>
                            Nama
                        </Col>
                        <Col lg={6} md={6}>
                            { props.kontakInfo && props.kontakInfo.nama ? props.kontakInfo.nama : ''}
                        </Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col lg={4} md={4}>
                            HP
                        </Col>
                        <Col lg={6} md={6}>
                            { props.kontakInfo && props.kontakInfo.hp ? props.kontakInfo.hp : ''}
                        </Col>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup row className='form-group-pos'>
                        <Col lg={4} md={4}>
                            Alamat
                        </Col>
                        <Col lg={6} md={6}>
                            { props.kontakInfo && props.kontakInfo.alamat ? props.kontakInfo.alamat : ''}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <div style={{marginTop: 20}}></div>
            <Row>
                <Col xs={12}>
                    <Table className='table-bordered table-small table-center table-striped'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tanggal</th>
                                <th>No Group</th>
                                {/* <th>Akun</th> */}
                                <th>Deskripsi</th>
                                <th>Debet</th>
                                <th>Kredit</th>
                            </tr>
                        </thead>
                        <tbody>
                            { parseInt(props.openBalance) > 0 &&
                                <tr>
                                    <td className="text-center" colSpan={4} style={{fontWeight: 'bold'}}>Open Balance</td>
                                    <td className="text-right"></td>
                                    <td className="text-right">{props.openBalance.toLocaleString()}</td>
                                </tr>
                            }
                            { props.data.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{post.nomor}</td>
                                        <td className="text-center">{post.tglreport}</td>
                                        <td>{post.nogroup}</td>
                                        {/* <td>{`${post.noakun} - ${post.nmakun}`}</td> */}
                                        <td>{post.trxname}</td>
                                        <td className="text-right">{post.ammdb.toLocaleString()}</td>
                                        <td className="text-right">{post.ammcr.toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        { props.data.length > 0 &&
                            <tfoot>
                                <tr>
                                    <th className='text-right' colSpan={4}>Total</th>
                                    <th className='text-right'>{props.totalData.totalAmmdb.toLocaleString()}</th>
                                    <th className='text-right'>{props.totalData.totalAmmcr.toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        }
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}
export default HutangDetailCetakComp;