import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as accountingService from '../../../services/accountingService';
import Moment from 'moment'

const PiutangPrint = (props) => {
    const [listData, setListData] = useState([]);
    const [tanggal, setTanggal] = useState('');
    const [totalPiutang, setTotalPiutang] = useState(0);


    const fetchData = useCallback(async(q='', page=1) => {
        try{
            const res = await accountingService.getPiutang(`tipe=penjualan&show=by_kontak_and_account&q=${q}`, 0, page);
            if (res.sukses === 'yes'){                
                setListData(res.data);
                let total = 0;
                res.data.map(post => {
                    total += parseInt(post.saldo);
                    return true;
                });
                setTotalPiutang(total);
            }                   
        }catch(error){            
            return;
        }
    },[])

    useEffect(() => {
        fetchData();
        let date = new Date()
        setTanggal(Moment(date).format('DD-MM-YYYY hh:mm'))
    },[fetchData])


    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Piutang Penjualan</div>
                        <div className="text-center" style={{fontStyle:'italic'}}>Tanggal Cetak {tanggal}</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table className='table-small table-bordered table-center' style={{marginTop: 20}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama</th>
                                    <th>Saldo Piutang</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{post.nomor}</td>
                                            <td>{post.nama_kontak}</td>
                                            <td className='text-right'>{post.saldo.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            { parseInt(totalPiutang) > 0 &&
                                <tfoot>
                                    <tr>
                                        <th className='text-right' colSpan={2}>Total Piutang</th>
                                        <th className='text-right'>{totalPiutang.toLocaleString()}</th>
                                    </tr>
                                </tfoot>
                            }
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default PiutangPrint