import React from 'react';
import { Fragment } from 'react';
import { Table, ButtonGroup, Button } from 'reactstrap';

const PengeluaranTableComp = (props) => {
    return(
        <Fragment>
            <Table responsive className='table-small table-bordered table-striped table-center'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Tanggal</th>
                        <th>Outlet</th>
                        <th>Nomor Transaksi</th>
                        <th>Diskripsi</th>
                        <th>Status</th>
                        <th>Total</th>
                        { props.tipe && props.tipe === 'show' &&
                            <th>Action</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td className='text-right'>{post.nomor}</td>
                                <td>{post.tanggal}</td>
                                <td>{post.outlet_name}</td>
                                <td>{post.nomor_transaksi}</td>
                                <td>
                                    { post.detail.map((detail, myindex) => {
                                        return(
                                            <div key={myindex}>{`${detail.keterangan} - ${detail.nominal.toLocaleString()}`}</div>
                                        )
                                    })}
                                </td>
                                <td className='text-center'>
                                    <span className={post.status === 'Unposting' ? 'badge badge-danger' : 'badge badge-success'}>
                                        {post.status}
                                    </span>
                                </td>  
                                <td className='text-right'>{post.total.toLocaleString()}</td>
                                { props.tipe && props.tipe === 'show' && 
                                    <Fragment>
                                        <td className='text-center'>
                                            <ButtonGroup>
                                                { post.status !== 'Unposting' && 
                                                    <Button className='btn-edit' onClick={() => props.handleDelete(post)} color='danger'><i className='fa fa-trash'></i></Button>
                                                }
                                            </ButtonGroup>
                                        </td>
                                    </Fragment>
                                }
                            </tr>
                        )
                    })}                    
                </tbody>
                { parseInt(props.total) > 0 &&                        
                    <tfoot>
                        <tr>
                            <th colSpan={5} className='text-right'>Total Pengeluaran</th>
                            <th className='text-right'>{props.total ? props.total.toLocaleString() : 0}</th>
                        </tr>
                    </tfoot>
                }
            </Table>
        </Fragment>
    )
}
export default PengeluaranTableComp;