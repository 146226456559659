import { createSlice } from '@reduxjs/toolkit';
import * as wilayahService from '../services/wilayahService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    kabupatens : [],
    detail : {}
}

const kabupatenStoreSlice = createSlice({
    name : 'kabupatenStore',
    initialState,
    reducers : {
        getData: (state) => { state.loading = true },
        getDataSuccess: (state, action) => { 
            state.loading = false; 
            state.hasErrors = false; 
            state.kabupatens = action.payload  
        },
        getDataFailure: (state) => { state.loading = false; state.hasErrors = true;},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.detail = action.payload;
        } 
    }
})


export const { getData, getDataSuccess, getDataFailure, setDetail } = kabupatenStoreSlice.actions;

// export const { kabupatenReducer } = kabupatenStoreSlice.reducer;
export const kabupatenSelector = (state) => state.kabupatens;

export default kabupatenStoreSlice.reducer;


// export function fetchKabupaten(page, q=''){
export function fetchKabupaten(q='', limit=config.itemPerPage, page=1){
    return async (dispatch) => {
        dispatch(getData());
        try{
            const response = await wilayahService.getKabupaten(`${q}`, limit, page);
            const data = await response;
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data));                
            }else{
                dispatch(getDataFailure());
            }            
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchDetail(id){
    return async (dispatch) => {
        dispatch(getData());
        try{
            const response = await wilayahService.getKabupaten(`id=${id}`, 1,1);
            const data = await response;
            if (data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}