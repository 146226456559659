import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Table, FormGroup  } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';

const PiutangPegawaiDetailPrint = (props) => {    
    const data = JSON.parse(localStorage.getItem('piutangPegawaiData')) || {};

    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Piutang Tenaga Kerja Detail</div>
                        <div className="text-center">Periode {data && data.startDateDisplay && data.startDateDisplay} s.d {data && data.endDateDisplay && data.endDateDisplay}</div>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}></div>
                <Row>
                    <Col lg={6}>
                        <div className='group-piutang'>
                            <FormGroup row className='form-group-pos'>
                                <Col lg={5}>
                                    Nama Pegawai
                                </Col>
                                <Col lg={7}>
                                    {data && data.info && data.info.nama}
                                </Col>
                            </FormGroup>
                            <FormGroup row className='form-group-pos'>
                                <Col lg={5}>
                                    HP
                                </Col>
                                <Col lg={7}>
                                    {data && data.info && data.info.hp}
                                </Col>
                            </FormGroup>
                            <FormGroup row className='form-group-pos'>
                                <Col lg={5}>
                                    Alamat
                                </Col>
                                <Col lg={7}>
                                    {data && data.info && data.info.alamat}
                                </Col>
                            </FormGroup>
                        </div>
                    </Col>
                    <Col xs={6}>                        
                            <FormGroup row className='form-group-pos'>
                                <Col lg={5}>
                                    Masih harus dibayar Rp.
                                </Col>
                                <Col lg={7}>
                                    {data && data.info && data.info.hutang && data.info.hutang.toLocaleString()}
                                </Col>
                            </FormGroup>
                            <FormGroup row className='form-group-pos'>
                                <Col lg={5}>
                                    Piutang Rp.
                                </Col>
                                <Col lg={7}>
                                    {data && data.info && data.info.piutang && data.info.piutang.toLocaleString()}
                                </Col>
                            </FormGroup>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}></div>
                <Row>
                    <Col lg={12}>
                        <Table responsive className='table-bordered table-small table-center'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tanggal</th>
                                    <th>Description</th>
                                    <th>Piutang / Pembayaran</th>
                                    <th>Belum Terbayar</th>
                                </tr>
                            </thead>
                            <tbody>
                                { data && (parseInt(data.saldoAmmcr) > 0 || parseInt(data.saldoAmmdb) > 0) &&
                                    <tr>
                                        <td className="text-center" colSpan={3}>Open Balance</td>
                                        <td className="text-right">{data && data.saldoAmmdb && data.saldoAmmdb.toLocaleString()}</td>
                                        <td className="text-right">{data && data.saldoAmmcr && data.saldoAmmcr.toLocaleString()}</td>
                                    </tr>
                                }
                                { data && data.listData && data.listData.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{post.nomor}</td>
                                            <td>{post.tglreport}</td>
                                            <td>{post.trxname}</td>
                                            <td className='text-right'>{post.ammdb.toLocaleString()}</td>
                                            <td className='text-right'>{post.ammcr.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>                    
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default PiutangPegawaiDetailPrint;