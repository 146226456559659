// import React, { useState } from 'react';

export function pageObj(count, limit, offset){
    let totalPages = Math.ceil(parseInt(count) / parseInt(limit));
    let currentPage ;
    if (currentPage < 0){
        currentPage = 1;
    }else{
        currentPage = (Math.ceil(parseInt(offset) / parseInt(limit))) + 1
    }
    
    
    let maxPages = 10;

    if (currentPage < 1){
        currentPage = 1;
    }else if(currentPage > totalPages){
        currentPage = totalPages
    }

    let startPage;
    let endPage;
    if (totalPages <= maxPages){
        startPage = 1;
        endPage = totalPages;
    }else{
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }
    // calculate start and end item indexes
    // let startIndex = (currentPage - 1) * limit;
    // let endIndex = Math.min(startIndex + limit - 1, res.count - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    return pages;
}