import React from 'react';
import { Fragment } from 'react';
import LaporanProfitLossCetakComp from '../Preview/LaporanProfitLossCetakComp';

const ProfitAndLossPrint = (props) => {
    const data = JSON.parse(localStorage.getItem('profitAndLossData')) || {}
    return(
        <Fragment>
            <div className='billprint-container'>
                <LaporanProfitLossCetakComp
                    info={data && data.info ? data.info : {}}
                    dateValue={data && data.dateValue ? data.dateValue : {}}
                />
            </div>
        </Fragment>
    )
}
export default ProfitAndLossPrint;