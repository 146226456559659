import React from 'react';
import { Fragment } from 'react';
import { Table } from 'reactstrap';

const LaporanProfitPenjualanTable = (props) => {
    return(
        <Fragment>  
            <Table responsive className='table-small table-center table-bordered'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Outlet</th>
                        <th>No Transaksi</th>
                        <th>Tanggal</th>
                        <th>Konsumen</th>
                        <th>Produk</th>
                        <th colSpan={2}>Qty</th>
                        <th colSpan={2}>Harga Jual</th>
                        <th>HPP</th>                                    
                        <th>Profit</th>
                    </tr>
                </thead>
                <tbody>
                    { props.data.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td className='text-right'>{post.nomor}</td>
                                <td>{post.outlet_name}</td>
                                <td>{post.no_transaksi}</td>
                                <td>{post.tanggal}</td>
                                <td>{post.nama_pelanggan}</td>
                                <td>{post.produk}</td>
                                <td className='text-right'>{post.qty}</td>
                                <td>{post.nama_satuan}</td>
                                <td className='text-right'>{(parseInt(post.harga) * parseInt(post.qty)).toLocaleString()}</td>
                                <td>{post.harga_name}</td>
                                <td className='text-right'>{(parseInt(post.hpp) * parseInt(post.qty)).toLocaleString()}</td>
                                <td className='text-right'>{((parseInt(post.harga) * parseInt(post.qty)) - (parseInt(post.hpp) * parseInt(post.qty)) ).toLocaleString()}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={11} className='text-right'>Total Profit</th>
                        <th className="text-right">{props.totalProfit.toLocaleString()}</th>
                    </tr>
                </tfoot>
            </Table>
        </Fragment>
    )
}
export default LaporanProfitPenjualanTable