import React from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PengeluaranTableComp from './PengeluaranTableComp';

const PengeluaranCetakComp = (props) => {
    return(
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Pembelian</div>
                    <div className="text-center">Periode {props.startDate} s.d {props.endDate}</div>
                </Col>
            </Row>
            <div style={{marginTop: 10}}/>
            <Row>
                <Col xs={12}>
                    <PengeluaranTableComp
                        tipe="cetak"
                        data={props.data}
                        total={props.total ? props.total : 0}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}
export default PengeluaranCetakComp;