import React, { useEffect, useState, Fragment } from 'react';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import PenjualanCetakComp from '../../Penjualan/PenjualanCetakComp';

const PenjualanPrint = (props) => {
    const [data, setData] = useState(localStorage.getItem('penjualanData') || {})
    const [listData, setListData] = useState([])
    const [totalValue, setTotalValue] = useState({
        diskon : 0,
        total : 0,
        cash : 0,
        deposite : 0,
        piutang : 0,
    })
    const [dateValue, setDateValue] = useState({
        startDate : '',
        endDate : ''
    })

    useEffect(() => {
        if (data){            
            let tmpData = JSON.parse(data);
            setListData(tmpData.listData);
            setTotalValue(totalValue => ({...totalValue,
                diskon : tmpData.totalData.diskon,
                total : tmpData.totalData.total,
                cash : tmpData.totalData.cash,
                deposite : tmpData.totalData.deposite,
            }))
            setDateValue(dateValue => ({...dateValue, 
                startDate : tmpData.startDate,
                endDate : tmpData.endDate
            }))
        }else{
            setData(localStorage.getItem('penjualanData'));
        } 
    },[data])
    return(
        <Fragment>
            <div className='billprint-container landscape'>
                <PerusahaanComp className='margin-bottom-10'/>
                <PenjualanCetakComp
                    startDate={dateValue.startDate}
                    endDate={dateValue.endDate}
                    data={listData}
                    totalData={totalValue}
                />
            </div>
        </Fragment>
    )
}
export default PenjualanPrint;