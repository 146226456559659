import React from 'react';
import { Fragment } from 'react';
import ProduksiDetailCetakComp from '../../Produksi/ProduksiDetailCetakComp';


const ProduksiDetailPrint = (props) => {
    const data = JSON.parse(localStorage.getItem('produksiDetailData') || {})
    
    return(
        <Fragment>
            <div className='billprint-container'>
                <ProduksiDetailCetakComp
                    data={data}
                />
            </div>
        </Fragment>
    )
}
export default ProduksiDetailPrint