import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import Moment from 'moment';


const ProdukPrint = (props) => {
    const data = JSON.parse(localStorage.getItem('produkData') || {})
    const [tanggal, setTanggal] = useState('');
    const [total, setTotal] = useState(0);

    useEffect(() => {        
        let tmpTanggal = Moment(new Date()).format('DD-MM-YYYY');
        setTanggal(tmpTanggal);        
        if (data){
            let tmp = 0
            data.data.map(post => {
                tmp += parseInt(post.saldo) * parseInt(post.harga_beli)                
                return true;
            })
            setTotal(tmp);
        }
    },[data])


    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp/>
                <div style={{marginTop:10}}/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Daftar Produk</div>
                        <div className="text-center">Tanggal Cetak {tanggal}</div>
                    </Col>
                </Row>
                <div style={{marginTop:20}}/>
                <Row>
                    <Col xs={12}>
                        <Table className='table table-bordered table-small table-center'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Kode</th>
                                    <th>Produk</th>
                                    <th>Kategori</th>
                                    <th>HPP</th>
                                    <th>Harga Jual</th>
                                    <th colSpan={2}>Saldo</th>
                                    <th>Nilai Asset</th>
                                </tr>
                            </thead>
                            <tbody>
                                { data.data.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{post.nomor}</td>
                                            <td>{post.kode}</td>
                                            <td>{post.nama}</td>
                                            <td>{post.nama_kategori}</td>
                                            <td className='text-right'>{post.harga_beli.toLocaleString()}</td>
                                            <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                            <td className='text-right'>{post.saldo}</td>
                                            <td>{post.nama_satuan}</td>
                                        <td className='text-right'>{ (parseInt(post.saldo) * parseInt(post.harga_beli)).toLocaleString() }</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={8} className='text-right'>Total Asset</th>
                                    <th className="text-right">{total.toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default ProdukPrint