// import 'react-app-polyfill/ie9'; // For IE 9-11 support
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/customStyle.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';

import rootReducer from './slices';
import { UserProvider } from './context/UserContext';

const store = configureStore({reducer: rootReducer});

ReactDOM.render(
    <UserProvider>

        <Provider store={store}>
            <App /> 
        </Provider>
    </UserProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
