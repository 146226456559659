import React from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import LaporanProfitPenjualanTable from '../Preview/LaporanProfitPenjualanTable';

const PenjualanProfitPrint = (props) => {
    const data = JSON.parse(localStorage.getItem('profitPenjualanData')) || {}

    return(
        <Fragment>
            <div className='billprint-container landscape'>
                <PerusahaanComp className='margin-bottom-10'/>
                <div style={{marginTop: 20}}/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Profit Penjualan</div>
                        <div className="text-center">Periode {data && data.startDate} s.d {data && data.endDate}</div>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}/>
                <LaporanProfitPenjualanTable
                    data={data && data.listData ? data.listData : []}
                    totalProfit={data && data.totalProfit ? data.totalProfit : 0}
                />
            </div>
        </Fragment>
    )
}
export default PenjualanProfitPrint;