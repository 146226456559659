import api from './api';

export function getProvinsi(keyword, limit, page){
    return api.get(`/master/api/provinsi?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


export function getKabupaten(keyword, limit, page){
    return api.get(`/master/api/kabupaten?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getKecamatan(keyword, limit, page){
    return api.get(`/master/api/kecamatan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getDesa(keyword, limit, page){
    return api.get(`/master/api/desa?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function updateKabupaten(formData){
    return api.push(`/master/api`, formData).then(res => res);
}
