import { createSlice } from '@reduxjs/toolkit';
import * as userService from '../services/userService';


const initialState = {
    loading : false,
    hasErrors : false,
    roles : [],
    role : {},
    list_page : [],
    is_superuser : false,
    pesan : ''
}

const roleStoreSlice = createSlice({
    name : 'roleStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.roles = action.payload;            
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.role = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.roles = state.roles.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        },
        setSuperUser : (state, action) => {
            state.is_superuser = action.payload
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan, setSuperUser } = roleStoreSlice.actions;

export const roleSelector = (state) => state.roles;

export default roleStoreSlice.reducer;


export function fetchRole(){
    return async(dispatch) => {
        dispatch(getData());
        try{
            let dataSession = sessionStorage.getItem('roleData');            
            dataSession = JSON.parse(dataSession);            
            if(!dataSession || dataSession.length === 0){
                const response = await userService.getAllRole();
                const data = await response;                    
                if(data.sukses === 'yes'){                    
                    dispatch(getDataSuccess(data.data))
                    dispatch(setSuperUser(data.is_superuser))                    
                    sessionStorage.setItem('isSuperUser', JSON.stringify(data.is_superuser));
                    sessionStorage.setItem('roleData', JSON.stringify(data.data));
                }else{
                    dispatch(getDataFailure());
                }
            }else{                
                let dataSuperUser = sessionStorage.getItem('isSuperUser');
                if (dataSuperUser){
                    dispatch(setSuperUser(JSON.parse(dataSuperUser)))
                }
                dispatch(getDataSuccess(JSON.parse(dataSession)))
            }                        
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}
