import React from 'react';
import { Fragment } from 'react';
import { Row, Col, FormGroup} from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';

const LaporanProfitLossCetakComp = (props) => {
    return(
        <Fragment>
            <Row>
                <Col xs={12}>
                    <PerusahaanComp className='margin-bottom-10'/>                            
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Profit and Loss</div>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>{props.info.outletName}</div>
                    <div className="text-center">Periode {props.dateValue.startDate} s.d {props.dateValue.endDate}</div>
                </Col>
            </Row>
            <div style={{marginTop: 20}}/>
            <div style={{borderTop: '1px solid #5e5e5e'}}/>
            <div style={{marginTop: 30}}/>
            <Row>
                <Col xs={12}>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Penjualan Kotor</Col>
                        <Col xs={4} className='text-right'>{props.info.penjualan.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Retur Penjualan</Col>
                        <Col xs={4} className='text-right'>{props.info.returPenjualan.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Diskon Penjualan</Col>
                        <Col xs={4} className='text-right'>{props.info.diskonPenjualan.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Penjualan Bersih</Col>
                        <Col xs={2}></Col>
                        <Col xs={2} className='text-right' style={{borderTop: '1px solid #5e5e5e'}}>
                            {(parseInt(props.info.penjualan) - parseInt(props.info.diskonPenjualan) - parseInt(props.info.returPenjualan)).toLocaleString()}
                        </Col>
                    </FormGroup>
                    <div style={{marginTop: 15}}/>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>HPP</Col>
                        <Col xs={2} className='text-right'>{props.info.hpp.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Biaya</Col>
                        <Col xs={2} className='text-right'>{props.info.biaya.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Total Biaya</Col>
                        <Col xs={2}></Col>
                        <Col xs={2} className='text-right' style={{borderBottom: '1px solid #5e5e5e'}}>{(parseInt(props.info.hpp) + parseInt(props.info.biaya)).toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos' style={{fontWeight: 'bold'}}>
                        <Col xs={4}>Laba Kotor</Col>
                        <Col xs={2}></Col>
                        <Col xs={2} className='text-right' style={{borderBottomWidth: '1px', borderBottomStyle: 'double', borderBottomColor: '#5e5e5e'}}>{props.info.profit.toLocaleString()}</Col>
                    </FormGroup>
                    <div style={{marginTop: 25}}/>
                    <FormGroup row className='form-group-pos'>
                        <Col xs={4}>Diskon Pembelian</Col>
                        <Col xs={2}></Col>
                        <Col xs={2} className='text-right' style={{borderBottom: '1px solid #5e5e5e'}}>{props.info.diskonPembelian.toLocaleString()}</Col>
                    </FormGroup>
                    <FormGroup row className='form-group-pos' style={{fontWeight: 'bold'}}>
                        <Col xs={4}>Laba Bersih</Col>
                        <Col xs={2}></Col>
                        <Col xs={2} className='text-right' style={{borderBottomWidth: '1px', borderBottomStyle: 'double', borderBottomColor: '#5e5e5e'}}>{(parseInt(props.info.profit) + parseInt(props.info.diskonPembelian)).toLocaleString()}</Col>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    )
}
export default LaporanProfitLossCetakComp;