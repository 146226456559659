import React from 'react';
import { Fragment } from 'react';
import { Col, Row, FormGroup } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import DepositeDetailCetakComp from '../../BackOffice/DepositeKonsumen/DepositeDetailCetakComp';


const DepositeDetailPrint = (props) => {
    const data = JSON.parse(localStorage.getItem('depositeData')) || {}
    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Deposite</div>
                        <div className="text-center">Periode {data.startDate} s.d {data.endDate}</div>
                    </Col>
                </Row>
                <div style={{marginTop: 10, marginBottom: 10, borderTop: '1px solid #5e5e5e'}}/>
                <Row>
                    <Col xs={6}>
                        <FormGroup row className='form-group-pos'>
                            <Col xs={4}>
                                Konsumen
                            </Col>
                            <Col xs={8}>
                                { data.kontakInfo.nama}
                            </Col>
                        </FormGroup>
                        <FormGroup row className='form-group-pos'>
                            <Col xs={4}>
                                HP
                            </Col>
                            <Col xs={8}>
                                { data.kontakInfo.hp}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup row className='form-group-pos'>
                            <Col xs={4}>
                                Alamat
                            </Col>
                            <Col xs={8}>
                                { data.kontakInfo.alamat}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}/>
                <Row>
                    <Col xs={12}>
                        <DepositeDetailCetakComp
                            openBalance={data.openBalance}
                            listData={data.listData}
                        />
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default DepositeDetailPrint;