import React from 'react';
import { Fragment } from 'react';
import { Row, Col, FormGroup, Table } from 'reactstrap';
import PerusahaanComp from '../../containers/Components/PerusahaanComp';

const ProduksiDetailCetakComp = (props) => {
    return(
        <Fragment>
            <PerusahaanComp className='margin-bottom-10'/>
            <Row>
                <Col xs={12}>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Produksi</div>
                    <div className="text-center">Kode Produksi {props.data && props.data.info && props.data.info.kode_produksi }</div>
                    <div className="text-center">{props.data && props.data.info && props.data.info.status_selesai_display }</div>
                </Col>
            </Row>
            <div style={{marginTop: 20}}/>
            <Row>
                <Col xs={6}>
                    <FormGroup className='form-group-pos' row>
                        <Col xs={4}>
                            Tanggal
                        </Col>
                        <Col xs={8}>: {props.data && props.data.info && props.data.info.tanggal}</Col>
                    </FormGroup>
                    <FormGroup className='form-group-pos' row>
                        <Col xs={4}>
                            Tanggal Dikerjakan
                        </Col>
                        <Col xs={8}>: {props.data && props.data.info && props.data.info.tanggal_dikerjakan}</Col>
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup className='form-group-pos' row>
                        <Col xs={4}>
                            Tanggal Selesai
                        </Col>
                        <Col xs={8}>: {props.data && props.data.info && props.data.info.tanggal_selesai}</Col>
                    </FormGroup>
                    <FormGroup className='form-group-pos' row>
                        <Col xs={4}>
                            Hasil Jumlah Adonan
                        </Col>
                        <Col xs={8}>: {props.data && props.data.info && props.data.info.jumlah_adonan}</Col>
                    </FormGroup>
                </Col>
            </Row>
            <div style={{marginTop: 10, border: '.5px solid #5e5e5e', marginBottom: 20}}/>
            <Row>
                <Col xs={6}>
                    <FormGroup>
                        <div style={{fontWeight: 700}}>Tenaga Kerja</div>
                    </FormGroup>
                    <Table className='table-bordered table-small table-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama Tenaga Kerja</th>
                                <th>Bagian</th>
                            </tr>
                        </thead>
                        <tbody>
                            { props.data && props.data.tenagaKerja.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1}</td>
                                        <td>{post.nama}</td>
                                        <td>{post.bagian}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <div style={{fontWeight: 700}}>Bahan Baku</div>
                    </FormGroup>
                    <Table className='table-bordered table-small table-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama Bahan Baku</th>
                                <th colSpan={2}>Jumlah</th>
                            </tr>
                        </thead>
                        <tbody>
                            { props.data && props.data.bahanBaku.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1}</td>
                                        <td>{post.produk_name}</td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td>{post.satuan_name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <div style={{fontWeight: 700}}>{props.data && props.data.info && parseInt(props.data.info.status_selesai) !== 3 ? 'Perkiraan' : ''}  Bahan Baku</div>
                    </FormGroup>
                    <Table className='table-bordered table-small table-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Jenis Biaya</th>
                                <th>Nama Biaya</th>                                        
                                <th colSpan={2}>Jumlah</th>
                                <th>Harga Satuan</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            { props.data && props.data.tenagaKerja.map((post, index) => {                                        
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1}</td>
                                        <td>Tenaga Kerja</td>
                                        <td>Tenaga Kerja an. {post.nama} bagian {post.bagian} </td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td>Adonan</td>
                                        <td className='text-right'>{post.hpp.toLocaleString()}</td>
                                        <td className='text-right'>{(parseInt(post.hpp) * parseInt(post.qty)).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                            { props.data && props.data.bahanBaku.map((post, index) => {                                        
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1+props.data.tenagaKerja.length}</td>
                                        <td>Bahan Baku</td>
                                        <td>{post.produk_name}</td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td>{post.satuan_name}</td>
                                        <td className='text-right'>{post.hpp.toLocaleString()}</td>
                                        <td className='text-right'>{(parseInt(post.hpp) * parseInt(post.qty)).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                            { props.data && props.data.biayaLainnya.map((post, index) => {                                        
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1+props.data.tenagaKerja.length+props.data.bahanBaku.length}</td>
                                        <td>Biaya Lainnya</td>
                                        <td>{post.nama_biaya}</td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td></td>
                                        <td className='text-right'>{post.hpp.toLocaleString()}</td>
                                        <td className='text-right'>{(parseInt(post.hpp) * parseInt(post.qty)).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <div style={{fontWeight: 700}}>Hasil Produksi</div>
                    </FormGroup>
                    <Table className='table-bordered table-small table-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Jenis Hasil Produksi</th>
                                <th>Nama Produk</th>
                                <th>Jumlah Adonan</th>                           
                                <th colSpan={2}>Hasil Produksi</th>                                        
                            </tr>
                        </thead>
                        <tbody>
                            { props.data && props.data.hasil.map((post, index) => {                                        
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1}</td>
                                        <td>Hasil Produksi</td>
                                        <td>{post.produk_name}</td>                                                
                                        <td className='text-right'>{post.jumlah_adonan.toLocaleString()}</td>
                                        <td className='text-right'>{post.qty.toLocaleString()}</td>
                                        <td>{post.satuan_name}</td>
                                    </tr>
                                )
                            })}
                            { props.data && props.data.ampas.map((post, index) => {                                        
                                return(
                                    <tr key={index}>
                                        <td className="text-right">{index+1+props.data.hasil.length}</td>
                                        <td>Ampas Produksi</td>
                                        <td>{post.produk_name}</td>                                                
                                        <td className='text-right'>-</td>
                                        <td className='text-right'>{post.qty.toLocaleString()}</td>
                                        <td>{post.satuan_name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}
export default ProduksiDetailCetakComp;