import React, { useEffect, useState, Fragment } from 'react';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import PembelianCetakComp from '../../Pembelian/PembelianCetakComp';


const PembelianPrint = (props) => {
    const [data, setData] = useState(localStorage.getItem('pembelianData') || {})
    const [listData, setListData] = useState([])
    const [totalValue, setTotalValue] = useState({
        diskon : 0,
        total : 0,
    })
    const [dateValue, setDateValue] = useState({
        startDate : '',
        endDate : ''
    })

    useEffect(() => {
        if (data){            
            let tmpData = JSON.parse(data);
            setListData(tmpData.listData);
            setTotalValue(totalValue => ({...totalValue,
                diskon : tmpData.totalData.diskon,
                total : tmpData.totalData.total,
                totalRetur : tmpData.totalData.totalRetur,
                totalItem : tmpData.totalData.totalItem
            }))
            setDateValue(dateValue => ({...dateValue, 
                startDate : tmpData.startDate,
                endDate : tmpData.endDate
            }))
        }else{
            setData(localStorage.getItem('pembelianData'))
        }
    },[data])
    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <PembelianCetakComp
                    startDate={dateValue.startDate}
                    endDate={dateValue.endDate}
                    data={listData}
                    totalData={totalValue}
                />
            </div>
        </Fragment>
    )
}
export default PembelianPrint;