import api from './api';

//  user
export function postUser(formData){
    return api.post(`/auth/api/user`, formData).then(res => res.data);
}

export function putUser(id, formData){
    return api.put(`/auth/api/user/${id}`, formData).then(res => res.data);
}

export function getUser(keyword, limit, page){
    return api.get(`/auth/api/user?${keyword}`, {params:{limit, page}}).then(res => res.data)
}


// group
export function getGroup(){
    return api.get(`/auth/api/group`).then(res => res.data);
}

export function getRole(){        
    return api.post(`/auth/api/get-user-role`).then(res => res.data);    
}

export function getAllRole(){        
    return api.get(`/auth/api/get-all-role`).then(res => res.data);    
}
