import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row, FormGroup, Table } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as penjualanService from '../../../services/penjualanService';

import '../../../style/strukStyle.scss';


const StrukPrint = (props) => {
    const penjualanId = localStorage.getItem('penjualanId') || 208;

    const [penjualan, setPenjualan] = useState({});

    const fetchData = useCallback(async() => {
        try{
            const res = await penjualanService.getPenjualan(`id=${penjualanId}`);            
            setPenjualan(res.data);
        }catch(error){
            return;
        }
    },[penjualanId])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return(
        <Fragment>
            <div className='strukprint-container'>
                <PerusahaanComp/>
                <div className="text-center title">Nota Penjualan</div>
                <div className="text-center desc" style={{borderBottom: '1px solid #cecece'}}>{penjualan && penjualan.no_transaksi}</div>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col xs={6}>
                                <FormGroup className='box-tanggal'>
                                    <div>Date :</div>
                                    <div>{penjualan && penjualan.tanggal}</div>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup className='box-tanggal'>
                                    <div className='text-right'>Kasir :</div>
                                    <div className='text-right'>{penjualan.kasir}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{borderBottom: '1px solid #cecece'}}></div>
                <Table>
                    <tbody>
                        { penjualan.detail && penjualan.detail.map((post, index) => {
                            return(
                                <tr key={index}>
                                    <td>{`${post.nama}`}</td>
                                    <td>{`${post.qty} x ${post.harga}`}</td>
                                    <td className='text-right'>{(parseInt(post.qty) * parseInt(post.harga)).toLocaleString()}</td>
                                </tr>
                            )
                        })}     
                        <tr className="tr-border">
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>                   
                        <tr className='tr-total'>
                            <td colSpan={2} className='text-right'>{parseInt(penjualan.diskon) > 0 ? 'Subtotal' : 'Total'}</td>
                            <td className='text-right'>{penjualan.sub_total && penjualan.sub_total.toLocaleString()}</td>
                        </tr>
                        { (parseInt(penjualan.diskon) > 0) && 
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Diskon</td>
                                    <td className='text-right'>-{penjualan.diskon && penjualan.diskon.toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right' >Total</td>
                                    <td className='text-right' style={{borderTop: '1px solid #5e5e5e'}}>{penjualan.total && penjualan.total.toLocaleString()}</td>
                                </tr>
                            </Fragment>                           
                        }
                        { parseInt(penjualan.deposite) > 0 &&
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Deposite</td>
                                    <td className='text-right'>-{penjualan.deposite.toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right' style={{borderTop: '1px solid #cecece'}}>Yang harus dibayar</td>
                                    <td className='text-right' style={{borderTop: '1px solid #cecece'}}>{(parseInt(penjualan.total) - parseInt(penjualan.deposite)).toLocaleString()}</td>
                                </tr>

                            </Fragment>
                        }
                        { parseInt(penjualan.tipe_pembayaran) === 1 &&
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Bayar</td>
                                    <td className='text-right'>{penjualan.bayar && penjualan.bayar.toLocaleString()}</td>
                                </tr>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Kembali</td>
                                    { parseInt(penjualan.deposite) > 0 ?                                        
                                        // <td className='text-right'>{penjualan.bayar && (parseInt(penjualan.bayar) - parseInt(penjualan.total) - parseInt(penjualan.deposite)).toLocaleString()}</td>                                        
                                        // <td className='text-right'>{parseInt(penjualan.bayar) - parseInt(penjualan.deposite)}</td>
                                        <td className='text-right'>{penjualan.bayar && ((parseInt(penjualan.bayar) + parseInt(penjualan.deposite)) - parseInt(penjualan.total)).toLocaleString()}</td>
                                        :
                                        <td className='text-right'>{penjualan.bayar && (parseInt(penjualan.bayar) - parseInt(penjualan.total)).toLocaleString()}</td>
                                    }
                                </tr>

                            </Fragment>
                        }                  
                    </tbody>
                </Table>                
                { penjualan.tipe_pembayaran && parseInt(penjualan.tipe_pembayaran) === 3 &&
                    <Row>
                        <Col xs={12}>
                            <div className="float-right">
                                <div style={{fontWeight: 'bold', marginBottom: 10}} className='text-right'>Deposite</div>
                                <table style={{border:'none !important'}} className='table-deposite-struk'>
                                    <tbody  style={{border:'none !important'}}>
                                        <tr>
                                            <td>Deposite</td>
                                            <td>:</td>
                                            <td className='text-right'>{penjualan && penjualan.deposite.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}}>Terpakai</td>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}}>:</td>
                                            <td style={{borderBottom: '1px solid #5e5e5e'}} className='text-right'>{penjualan && penjualan.total.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Saldo Deposite</td>
                                            <td>:</td>
                                            <td className='text-right'>{penjualan && (parseInt(penjualan.deposite) - parseInt(penjualan.total)).toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>    
                            </div>                        
                        </Col>
                    </Row>    
                }
                { penjualan.tipe_pembayaran && [2,3].includes(parseInt(penjualan.tipe_pembayaran)) &&
                    <Row>
                        <Col xs={6} className='offset-xs-6'>
                            <div style={{marginTop: 20}}/>
                            <FormGroup>
                                <div className='text-center'>
                                    { parseInt(penjualan.tipe_pembayaran) === 2 ? 'Penerima' : 'Konsumen' }
                                </div>
                                { parseInt(penjualan.tipe_pembayaran) === 2 &&
                                    <div className='text-center'> {penjualan.tipe_pembayaran_display} :</div>
                                }
                                <div className='text-center' style={{marginTop: 60}}>{penjualan.nama_pelanggan}</div>
                            </FormGroup>
                        </Col>
                    </Row>                
                }

            </div>
        </Fragment>
    )
}
export default StrukPrint