import { createSlice } from '@reduxjs/toolkit';
import * as perusahaanService from '../services/perusahaanService';
export const initialState = {
    loading : false,
    hasErrors : false,
    posts: [],
}

const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers : {
        getPosts: (state) => {
            state.loading = true
        },
        getPostsSuccess: (state, {payload}) => {
            state.posts = payload
            state.loading = false
            state.hasErrors = false
        },
        getPostsFailure : (state) => {
            state.loading = false
            state.hasErrors = true
        }
    }
})

// Three actions generated from the slice
export const {getPosts, getPostsSuccess, getPostsFailure} = postsSlice.actions;

// A selector
export const postsSelector = (state) => state.posts

// The reducer
export default postsSlice.reducer;


// Asyncrounus thunk action 
export function fetchPost(){
    return async (dispatch) => {
        dispatch(getPosts());
        try{
            const response = await perusahaanService.getData(``, 1, 1);
            const data = await response;
            dispatch(getPostsSuccess(data));
        }catch(error){
            dispatch(getPostsFailure());
        }
    }
}
