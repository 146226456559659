import React from 'react';
import { Fragment } from 'react';
import { Table } from 'reactstrap';

const DepositeDetailCetakComp = (props) => {
    return(
        <Fragment>
            <Table responsive className='table-small table-bordered table-center'>
                <thead>
                    <tr>                                        
                        <th>Tanggal</th>
                        <th>Jenis Transaksi</th>
                        <th>Description</th>
                        <th>Debet</th>
                        <th>Kredit</th>
                        <th>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    { parseInt(props.openBalance) > 0 &&
                        <tr>
                            <td className='text-center' colSpan={5}>Open Balance</td>
                            <td className='text-right'>{props.openBalance.toLocaleString()}</td>
                        </tr>
                    }
                    { props.listData.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td>{post.tglreport}</td>
                                <td>{post.jenis_transaksi}</td>
                                <td>{post.trxname}</td>
                                <td>{post.ammdb.toLocaleString()}</td>
                                <td>{post.ammcr.toLocaleString()}</td>
                                <td>{post.saldo.toLocaleString()}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Fragment>
    )
}
export default DepositeDetailCetakComp;