import { createSlice } from '@reduxjs/toolkit';
import * as accountingService from '../services/accountingService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    akuns : [],
    akun : {},
    list_page : [],
    pesan : ''
}

const akunStoreSlice = createSlice({
    name : 'akunStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.akuns = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.akun = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.akuns = state.akuns.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = akunStoreSlice.actions;

export const akunSelector = (state) => state.akuns;

export default akunStoreSlice.reducer;


export function fetchAkun(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await accountingService.getAkun(q, limit, page);            
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}


export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await accountingService.getAkun(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

