import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';

const PenjualanCetakComp = (props) => {
    return(
        <Fragment>            
            <Row>
                <Col xs={12}>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Penjualan</div>
                    <div className="text-center">Periode {props.startDate} s.d {props.endDate}</div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table className='table-small table-bordered table-center' style={{marginTop: 20}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>No Transaksi</th>
                                <th>Tanggal</th>
                                <th>Konsumen</th>
                                <th>Detail</th>
                                <th>Diskon</th>
                                <th>Total</th>
                                <th>Cash</th>
                                <th>Piutang</th>
                                <th>Potong Deposite</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nomor}</td>
                                        <td>{post.no_transaksi}</td>
                                        <td>{post.tanggal}</td>
                                        <td>{post.nama_pelanggan ? post.nama_pelanggan : 'Walkin'}</td>
                                        <td>
                                            { post.produk.map((produk, myindex) => {
                                                return(
                                                    <div key={myindex}>{produk}</div>
                                                )
                                            })}
                                        </td>                                        
                                        <td className='text-right'>{post.diskon.toLocaleString()}</td>
                                        <td className='text-right'>{post.total.toLocaleString()}</td>
                                        <td className='text-right'>{ post.tipe_pembayaran === 1 ? post.total.toLocaleString() : 0}</td>
                                        <td className='text-right'>{ post.tipe_pembayaran === 2 ? post.total.toLocaleString() : 0}</td>
                                        <td className='text-right'>{ post.tipe_pembayaran === 3 ? post.total.toLocaleString() : 0}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        { props.data.length > 0 &&
                            <tfoot>
                                <tr>
                                    <th className="text-right" colSpan={5}>Total</th>
                                    <th className="text-right">{props.totalData.diskon.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.total.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.cash.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.piutang.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.deposite.toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        }
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}
export default PenjualanCetakComp;