import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Row, FormGroup, Label, Table } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as pembelianService from '../../../services/pembelianService';

const PembelianDetailPrint = (props) => {
    const pembelianId = localStorage.getItem('pembelianId') || 0;
    const [info, setInfo] = useState({        
        no_transaksi : '',
        suplier : '',
        tanggal : '',
        diskon : '',
        keterangan : '',
        jth_tempo : '',
        suplier_id : '',                        
    })
    const [listProduk, setListProduk] = useState([]);

    const fetchDetail = useCallback(async(id=0) => {        
        try{
            const res = await  pembelianService.getPembelian(`id=${id}`, 1, 1);            
            if (res.sukses === 'yes'){
                setInfo(formData => ({...formData,
                    no_transaksi : res.data.no_transaksi, 
                    suplier : res.data.suplier,
                    tanggal : res.data.tanggal,
                    diskon : res.data.diskon,
                    keterangan : res.data.keterangan,
                    jth_tempo : res.data.jth_tempo,
                    suplier_id : res.data.suplier_id,   
                    total_invoice : res.data.total_invoice                 
                }))
            }
            let tmp = []
            res.data.detail.map(post => {
                tmp.push({
                    id : {readOnly : true, value : post.id, showError : false, name : 'id'},
                    is_retur : {readOnly : true, value : false, showError : false, name : 'is_retur'},
                    qty : {readOnly : true, value : post.qty, showError : false, name : 'qty'},
                    qty_retur : {readOnly : true, value : 0, showError : false, name : 'qty_retur', errorMsg : 'Lengkapi Data'},
                    kode : {readOnly : true, value : post.kode, showError : false, name : 'kode'},
                    nama_produk : {readOnly : true, value : post.nama_produk, showError : false, name : 'nama_produk'}, 
                    hrg_satuan : {readOnly : true, value : post.harga, showError : false, name : 'hrg_satuan'},
                    total : {readOnly : true, value : post.total, showError : false, name : 'total'},
                    nama_satuan : {readOnly : true, value : post.nama_satuan, showError : false, name : 'nama_satuan'},
                })                
                return true;
            })
            setListProduk(tmp);            
        }catch(error){            
            return
        }
    },[])

    useEffect(() => {
        fetchDetail(pembelianId);
    },[fetchDetail, pembelianId])
    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <Row>
                    <Col xs={12}>
                        <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Pembelian</div>
                        <div className="text-center" style={{marginBottom: 20}}>NOMOR {info.no_transaksi}</div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Nomor Invoice</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.no_transaksi}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Suplier</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.suplier}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Tanggal</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.tanggal}</Label>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Tanggal Jth Tempo</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.jth_tempo}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Diskon</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.diskon}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row className='form-group-pembelian-cetak'>
                            <Col lg={5} md={5}>
                                <Label>Keterangan</Label>
                            </Col>
                            <Col lg={4} md={4}>
                                <Label>{info.keterangan}</Label>
                            </Col>
                        </FormGroup>                    
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table className='table-small table-bordered table-center' style={{marginTop: 20}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Kode</th>
                                    <th>Nama Produk</th>
                                    <th colSpan={2}>QTY</th>
                                    <th>Harga Satuan</th>                                    
                                    <th>Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                { listProduk.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{index+1}</td>
                                            <td>{post.kode.value}</td>
                                            <td>{post.nama_produk.value}</td>
                                            <td className='text-right'>{post.qty.value}</td>
                                            <td>{post.nama_satuan.value}</td>
                                            <td className='text-right'>{post.hrg_satuan.value.toLocaleString()}</td>
                                            <td className='text-right'>{post.total.value.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-right' colSpan={6}>{parseInt(info.diskon) > 0 ? 'Sub Total' : 'Total'}</th>
                                    <th className='text-right'>{info.total_invoice && info.diskon && (parseInt(info.total_invoice) + parseInt(info.diskon)).toLocaleString()}</th>
                                </tr>
                                { parseInt(info.diskon) > 0 &&
                                    <Fragment>
                                        <tr>
                                            <th className='text-right' colSpan={6}>Diskon</th>
                                            <th className='text-right'>{info.diskon && info.diskon.toLocaleString()}</th>
                                        </tr>
                                        <tr>
                                            <th className='text-right' colSpan={6}>Total</th>
                                            <th className='text-right'>{info.total_invoice && info.total_invoice.toLocaleString()}</th>
                                        </tr>
                                    </Fragment>
                                }
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default PembelianDetailPrint;