import api from './api';

// akun
export function getAkun(keyword, limit, page){
    return api.get(`/akunting/api/master-akun?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

// default akun
export function getDefaultAkun(keyword, limit, page){
    return api.get(`/akunting/api/default-akun?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postBulkDefaultAkun(form){
    return api.post(`/akunting/api/default-akun`, form).then(res => res.data);
}

// bank
export function getBank(keyword, limit, page){
    return api.get(`/akunting/api/bank?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postBank(form){
    return api.post(`/akunting/api/bank`, form).then(res => res.data);
}

export function putBank(id, form){
    return api.put(`/akunting/api/bank/${id}`, form).then(res => res.data);
}


export function postAkun(formData){
    return api.post(`/akunting/api/master-akun`, formData).then(res => res.data);
}

export function putAkun(id, formData){
    return api.put(`/akunting/api/master-akun/${id}`, formData).then(res => res.data);
}

// method pembayaran
export function postMethodPembayaran(formData){
    return api.post(`/akunting/api/method-pembayaran`, formData).then(res => res.data);
}

export function putMethodPembayaran(id, formData){
    return api.put(`/akunting/api/method-pembayaran/${id}`, formData).then(res => res.data);
}

export function getMethodPembayaran(keyword, limit, page){
    return api.get(`/akunting/api/method-pembayaran?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteMethodPembayaran(id){
    return api.delete(`/akunting/api/method-pembayaran/${id}`).then(res => res.data);
}

// jurnal
export function getJurnal(keyword, limit, page){
    return api.get(`/akunting/api/jurnal?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


// piutang
export function getPiutang(keyword, limit, page){
    return api.get(`/akunting/api/piutang?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

// hutang
export function getHutang(keyword, limit, page){
    return api.get(`/akunting/api/hutang?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postPembayaranHutang(formData){
    return api.post(`/akunting/api/bayar-hutang`, formData).then(res => res.data);
}

// pengeluaran
export function postPengeluaran(formData){
    return api.post(`/akunting/api/pengeluaran`, formData).then(res => res.data);
}

export function putPengeluaran(formData, id){
    return api.put(`/akunting/api/pengeluaran/${id}`, formData).then(res => res.data);
}

export function deletePengeluaran(id){
    return api.delete(`/akunting/api/pengeluaran/${id}`).then(res => res.data);
}
export function getPengeluaran(keyword, limit, page){
    return api.get(`/akunting/api/pengeluaran?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


export function getProfitAndLoss(keyword){
    return api.get(`/akunting/api/profit-and-loss?${keyword}`).then(res => res.data);    
}

// deposite
export function postDeposite(formData){
    return api.post(`/akunting/api/deposite`, formData).then(res => res.data);    
}

export function getDeposite(keyword, limit, page){
    return api.get(`/akunting/api/deposite?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postRefundDeposite(formData){
    return api.post(`/akunting/api/refund-deposite`, formData).then(res => res.data);
}

export function getDepositeDetail(keyword, limit, page){
    return api.get(`/akunting/api/deposite-log?${keyword}`, {params:{limit, page}}).then(res => res.data);
}
