import React, { useEffect, useState, Fragment } from 'react';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import HutangDetailCetakComp from '../../BackOffice/Hutang/HutangDetailCetakComp';
// import PembelianCetakComp from '../../Pembelian/PembelianCetakComp';


const HutangDetailPrint = (props) => {
    const [data, setData] = useState(localStorage.getItem('hutangDetailData') || {})
    const [listData, setListData] = useState([])
    const [totalValue, setTotalValue] = useState({
        totalAmmdb : 0,
        totalAmmcr : 0,
    })
    const [dateValue, setDateValue] = useState({
        startDate : '',
        endDate : ''
    });
    const [openBalance, setOpenBalance] = useState(0);
    const [kontakInfo, setKontakInfo] = useState({});

    useEffect(() => {
        if (data){            
            let tmpData = JSON.parse(data);
            setListData(tmpData.listData);
            setTotalValue(totalValue => ({...totalValue,                
                totalAmmdb : tmpData.totalData.totalAmmdb,
                totalAmmcr : tmpData.totalData.totalAmmcr,
            }))
            setDateValue(dateValue => ({...dateValue, 
                startDate : tmpData.startDate,
                endDate : tmpData.endDate
            }));
            setKontakInfo(tmpData.kontakInfo);
            setOpenBalance(tmpData.openBalance);
        }else{
            setData(localStorage.getItem('hutangDetailData'))
        }
    },[data])
    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <HutangDetailCetakComp
                    startDate={dateValue.startDate}
                    endDate={dateValue.endDate}
                    data={listData}
                    totalData={totalValue}
                    kontakInfo={kontakInfo}
                    openBalance={openBalance}
                />
            </div>
        </Fragment>
    )
}
export default HutangDetailPrint;