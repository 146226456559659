import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Table } from 'reactstrap';

const PembelianCetakComp = (props) => {
    return(
        <Fragment>            
            <Row>
                <Col xs={12}>
                    <div className="text-center" style={{fontWeight: 'bold', fontSize: 15}}>Laporan Pembelian</div>
                    <div className="text-center">Periode {props.startDate} s.d {props.endDate}</div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table className='table-small table-bordered table-center' style={{marginTop: 20}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>No Invoice</th>
                                <th>Tanggal</th>
                                <th>Suplier</th>
                                <th className='text-right'>Jumlah Item</th>
                                <th className='text-right'>Jumlah Retur</th>
                                <th className='text-right'>Diskon</th>
                                <th className='text-right'>Total Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nomor}</td>
                                        <td>{post.no_transaksi}</td>
                                        <td>{post.tanggal}</td>
                                        <td>{post.suplier}</td>
                                        <td className='text-right'>{post.count_item}</td>
                                        <td className='text-right'>{post.count_retur}</td>
                                        <td className='text-right'>{post.diskon.toLocaleString()}</td>
                                        <td className='text-right'>{post.total_invoice.toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        { props.data.length > 0 &&
                            <tfoot>
                                <tr>
                                    <th className="text-right" colSpan={4}>Total</th>
                                    <th className="text-right">{props.totalData.totalItem.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.totalRetur.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.diskon.toLocaleString()}</th>
                                    <th className="text-right">{props.totalData.total.toLocaleString()}</th>
                                </tr>
                            </tfoot>
                        }
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}
export default PembelianCetakComp;