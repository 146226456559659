import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import * as masterService from '../../services/masterService'

const PerusahaanComp = (props) => {
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : ''
    });
    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    return(
        <div className={props.className ? props.className : ''}>
            <Row>
                <Col xs={12}>
                    <div className="company-name">
                        {compInfo.nama}
                    </div>
                    <div className="company-address">                            
                        { compInfo.alamat }
                    </div>
                    <div className="company-city">
                        { compInfo.kota }
                    </div>
                    <div className="company-phone">
                        { compInfo.telp }
                    </div>
                </Col>                    
            </Row>
        </div>
    )
}
export default PerusahaanComp