export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        development: "http://workspacecetak.myappscenter.com",
        production: "http://workspacecetak.myappscenter.com", 
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
      OUTLET_LIST : '_outletList'
    },    
    expiresSession : 86400,
    itemPerPage : 20,
    timeOutValue : 5000,
    endPoint : {
      outlet : '/master/api/outlet/',
      bank : `/akunting/api/bank`,
      orderPenjualan : `/penjualan/api/v1/order-penjualan/`,
      produkDetail : '/produk/api/produk-detail/',
      saldoAwal : '/produk/api/saldo-awal/',
      hargaJual : '/produk/api/harga-jual/',
      hargaJualSatuan : '/produk/api/harga-jual-satuan/',
      produkLog : `/produk/api/v1/produklog/`,
      kategoriHarga : `/produk/api/v1/kategori-harga/`,
    }
};
  